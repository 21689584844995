import React, { useEffect } from 'react';

import { Analytics } from '@circleci/analytics';
import { CompassThemeProvider } from '@circleci/compass';
import theme from '@circleci/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import { ThemeProvider } from 'emotion-theming';
import cookies from 'js-cookie';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { NextPageContext } from 'next/types';
import Modal from 'react-modal';

import OptimizelyFullStackProvider from '~/components/OptimizelyFullStackProvider';
import { gaveConsent } from '~/components/shared/cookieConsent';
import { Provider as I18nProvider } from '~/i18n';
import { Language } from '~/i18n/messages';
import computeRedirect from '~/utils/computeRedirect';
// Initialize Datadog RUM
import '~/utils/realUserMonitoring';
import getHostname from '~/utils/getHostname';

type Props = any;

const clientIdKey = 'analytics-client-id';

export interface _AppProps extends AppProps {
  ctx: NextPageContext;
}

const App = ({ Component, pageProps, router }: Props) => {
  const locale: Language = (router.query && router.query.lang) || 'en';
  const returnTo = (router.query && router.query['return-to']) || '';

  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: false } },
  });

  useEffect(() => {
    // The following call check if the user is signed it and if not, it will stay in the sign up page
    // otherwise it will redirect to the returnTo prop in oder to accept an invitation.
    if (returnTo !== '') {
      axios
        .get(`https://${getHostname()}/api/v2/me`)
        .then((resp) => {
          if (resp.status === 200 && typeof window !== 'undefined') {
            window.location.href = computeRedirect(decodeURI(returnTo));
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          return console.log(err.message);
        });
    }
  }, []);

  useEffect(() => {
    // only drop cookies if consent was given
    if (gaveConsent('statistics')) {
      const clientId = cookies.get(clientIdKey) || Date.now();
      cookies.set(clientIdKey, clientId.toString(), {
        domain: '.circleci.com',
        expires: 365 * 2,
      });
    }

    Modal.setAppElement('#__next');
  }, []);

  return (
    <div>
      <Head>
        <title>CircleCI</title>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1'
        />
      </Head>
      <I18nProvider locale={locale}>
        <QueryClientProvider client={queryClient}>
          <OptimizelyFullStackProvider>
            <Analytics isOuterPage={true}>
              <CompassThemeProvider>
                <ThemeProvider theme={theme}>
                  <Component {...pageProps} />
                </ThemeProvider>
              </CompassThemeProvider>
            </Analytics>
          </OptimizelyFullStackProvider>
        </QueryClientProvider>
      </I18nProvider>
    </div>
  );
};

App.getInitialProps = async ({ Component, ctx }: _AppProps): Promise<Props> => {
  let pageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }

  return { pageProps };
};

export default App;
