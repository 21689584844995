declare global {
  interface Window {
    Cookiebot: any;
  }
}

export const gaveConsent = (category: string) => {
  if (typeof window !== 'undefined') {
    const cookiebot = window.Cookiebot || {};

    if (cookiebot.consented) {
      return cookiebot.consent[category];
    }

    return false;
  } else {
    return true;
  }
};
