import * as React from 'react';

import { PageLoadingIndicator } from '@circleci/compass';
import { createClient } from '@circleci/react-experiments';
import ExperimentProvider from '@circleci/react-experiments/build/utils/ExperimentProvider';
import { ReactSDKClient } from '@optimizely/react-sdk';

import getIsEnterprise from '~/utils/getIsEnterprise';

const OptimizelyFullStackProvider = ({ children }: any) => {
  const [optimizelyClient, setOptimizelyClient] =
    React.useState<ReactSDKClient>();
  const [optimizelyClientReady, setOptimizelyClientReady] =
    React.useState(false);

  React.useEffect(() => {
    if (!optimizelyClient) {
      const client = createClient(getIsEnterprise(), 'error');
      setOptimizelyClient(client);
    }

    if (optimizelyClient) {
      optimizelyClient
        .onReady({
          timeout: 5000,
        })
        .then(() => {
          setOptimizelyClientReady(true);
        });
    }
  }, [optimizelyClient]);

  // Avoid rendering ExperimentProvider without optimizelyClient being set since that would
  // cause a duplicate initialization of the Optimizely client due to the default props logic
  if (!optimizelyClient) {
    return <PageLoadingIndicator containerHeight='100vh' />;
  }

  return (
    <ExperimentProvider optimizelyClient={optimizelyClient}>
      {optimizelyClientReady ? (
        children
      ) : (
        <PageLoadingIndicator containerHeight='100vh' />
      )}
    </ExperimentProvider>
  );
};

export default OptimizelyFullStackProvider;
