export default (redirect: string) => {
  const domain = 'circleci.com';
  const defaultRedirect = '/dashboard';
  const url = new URL(redirect, `https://${domain}`);
  if (url.hostname === domain || url.hostname.endsWith(`.${domain}`)) {
    return redirect;
  }

  return defaultRedirect;
};
